import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import styles from '../../ProxyContent/ProxyContent.module.scss'

// import { getNormalizedPrice } from 'utils/functions'

import { Check as CheckIcon, BookOpen as BookIcon } from '../../../../icons'
// import ProductPricingBlock from 'components/elements/blocks/ProductPricingBlock'
import TabsList from 'components/elements/tabs/TabsList'
import CurlResult from './CurlResult'
import CredentialsResult from './CredentialsResult'
import UrlResult from './UrlResult'
import TabPanels from 'components/elements/tabs/TabPanels'
import Accordion from 'components/elements/blocks/Accordion'
import { IProxyPackage } from '../../../../../models/user'
/*import {
  selectProductPrice,
  selectProductPriceFetching,
} from 'store/pricing/selectors'*/
import { selectAvailableHosts } from 'store/user/selectors'
import InputGroup from 'components/elements/forms/InputGroup'
import CustomRadio from 'components/elements/inputs/CustomRadio'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'

interface IProxyConnectResultProps {
  selectedProxyType: any
  selectedPackage: IProxyPackage
  urlResultString: string
  credResultString: string
  curlResultString: string
  selectedSpaceSymbol: string
  selectedAmpersandSymbol: string
  selectedHostValue: string
  selectedOutputMask: ISelectOption
  onSpaceSymbolChange: (value: string) => void
  onAmpersandSymbolChange: (value: string) => void
  onHostValueChange: (value: string) => void
  onOutputMaskChange: (option: ISelectOption) => void
}

const ProxyQuickResult: React.FC<IProxyConnectResultProps> = (props) => {
  const {
    /*selectedProxyType,*/
    selectedPackage,
    urlResultString,
    credResultString,
    curlResultString,
    selectedSpaceSymbol,
    selectedAmpersandSymbol,
    selectedHostValue,
    selectedOutputMask,
    onOutputMaskChange,
    onSpaceSymbolChange,
    onAmpersandSymbolChange,
    onHostValueChange,
  } = props

  const tabItems = [
    {
      label: 'Username & password',
    },
    {
      label: 'URL',
    },
    {
      label: 'cURL request',
    },
  ]

  const [selectedResultTypeIndex, setSelectedResultTypeIndex] = useState(0)

  const availableHosts = useSelector(selectAvailableHosts)

  // const productPrice = useSelector(selectProductPrice)
  // const productPriceFetching = useSelector(selectProductPriceFetching)

  /*const getProductName = () => {
    if (selectedProxyType && selectedProxyType.shortLabel) {
      return `Proxy: ${selectedProxyType.shortLabel} pool`
    } else {
      return ''
    }
  }*/

  // const productName = getProductName()

  // const normalizedPrice = getNormalizedPrice(productPrice)

  // const productPriceString = productPrice ? `$${normalizedPrice} per 1 GB` : ''

  const handleResultTypeChange = (index: number) => {
    setSelectedResultTypeIndex(index)
  }

  const handleSpaceSymbolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSpaceSymbolChange(e.target.value)
  }

  const handleAmpersandSymbolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAmpersandSymbolChange(e.target.value)
  }

  const handleHostValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onHostValueChange(e.target.value)
  }

  const credPassword = selectedPackage.package_key
  const credPort = '5000'

  const tabContentArray = [
    <CredentialsResult
      login={credResultString}
      password={credPassword}
      server={selectedHostValue}
      port={credPort}
    />,
    <UrlResult
      result={urlResultString}
      selectedOutputMask={selectedOutputMask}
      onOutputMaskChange={onOutputMaskChange}
    />,
    <CurlResult result={curlResultString} />,
  ]

  const guideLinksArray = [
    <a
      className={styles.guideLink}
      href="https://helpcenter.soax.com/en/collections/3146082-software-tutorials"
      target={'_blank'}
      rel={'noopener noreferrer'}
    >
      <div className={styles.bookIconWrapper}>
        <BookIcon />
      </div>{' '}
      <span>Need help with setting up your browser, plugin or app?</span>
    </a>,
    null,
    <a
      className={styles.guideLink}
      href="https://helpcenter.soax.com/en/articles/10125344-proxy-set-up-using-curl-php-net-with-c-or-python-beta"
      target={'_blank'}
      rel={'noopener noreferrer'}
    >
      <div className={styles.bookIconWrapper}>
        <BookIcon />
      </div>{' '}
      <span>How to set up proxies using CURL, PHP, .NET, C# or Python</span>
    </a>,
  ]

  const renderGuideLink = () => {
    return guideLinksArray[selectedResultTypeIndex]
  }

  return (
    <div className={styles.blockMain}>
      <div className={styles.blockBackgroundHeader}>
        <span className={styles.blockTitle}>Proxy credentials</span>

        <div>
          <div className={styles.checkIconWrapper}>
            <CheckIcon />
          </div>
          Ready
        </div>
      </div>

      {/*<ProductPricingBlock
        className={styles.pricingBlock}
        product={productName}
        price={productPriceString}
        loading={productPriceFetching}
      />*/}

      <div className={styles.resultContent}>
        <InputGroup labelTop={'Connection method'} boldLabel>
          <div className={styles.groupInnerBlock} />
        </InputGroup>

        <div className={styles.resultTabsWrapper}>
          <TabsList
            items={tabItems}
            type={'bordered'}
            onChange={handleResultTypeChange}
            activeTabIndex={selectedResultTypeIndex}
            className={styles.resultTabs}
          />
        </div>

        <TabPanels
          className={styles.resultMain}
          items={tabContentArray}
          activeTabIndex={selectedResultTypeIndex}
        />

        <div
          className={`${selectedResultTypeIndex === 2 ? styles.hidden : ''}`}
        >
          <div className={styles.resultNotice}>
            To get a new IP address with the same configuration, please change
            the <b>session name</b> (step 3).
          </div>

          <Accordion
            title={'Advanced output options'}
            className={styles.optionsAccordion}
          >
            <div className={styles.advancedOptions}>
              <InputGroup
                labelTop={'Replace space symbol in location options with:'}
                boldLabel
                className={styles.advancedOptionsInputGroup}
              >
                <CustomRadio
                  id={'%20_space-radio'}
                  name={'space-symbol'}
                  value={'%20'}
                  checked={selectedSpaceSymbol === '%20'}
                  onChange={handleSpaceSymbolChange}
                  className={styles.radio}
                >
                  %20
                </CustomRadio>
                <CustomRadio
                  id={'+_space-radio'}
                  name={'space-symbol'}
                  value={'+'}
                  checked={selectedSpaceSymbol === '+'}
                  onChange={handleSpaceSymbolChange}
                  className={styles.radio}
                >
                  +
                </CustomRadio>
                <CustomRadio
                  id={'__space-radio'}
                  name={'space-symbol'}
                  value={'_'}
                  checked={selectedSpaceSymbol === '_'}
                  onChange={handleSpaceSymbolChange}
                  className={styles.radio}
                >
                  _
                </CustomRadio>
                <CustomRadio
                  id={'default_space-radio'}
                  name={'space-symbol'}
                  value={' '}
                  checked={selectedSpaceSymbol === ' '}
                  onChange={handleSpaceSymbolChange}
                  className={styles.radio}
                >
                  don't replace
                </CustomRadio>
              </InputGroup>

              <InputGroup
                labelTop={'Replace “&” symbol in location options with:'}
                boldLabel
                className={styles.advancedOptionsInputGroup}
              >
                <CustomRadio
                  id={'quick-result_%26_ampersand-radio'}
                  name={'quick-result_ampersand-symbol'}
                  value={'%26'}
                  checked={selectedAmpersandSymbol === '%26'}
                  onChange={handleAmpersandSymbolChange}
                  className={styles.radio}
                >
                  %26
                </CustomRadio>
                <CustomRadio
                  id={'quick-result_default_ampersand-radio'}
                  name={'quick-result_ampersand-symbol'}
                  value={'&'}
                  checked={selectedAmpersandSymbol === '&'}
                  onChange={handleAmpersandSymbolChange}
                  className={styles.radio}
                >
                  don't replace
                </CustomRadio>
              </InputGroup>

              <InputGroup
                labelTop={'Use for host:'}
                boldLabel
                className={styles.advancedOptionsInputGroup}
              >
                <CustomRadio
                  id={'default_host-radio'}
                  name={'host-value'}
                  value={availableHosts.domain}
                  checked={selectedHostValue === availableHosts.domain}
                  onChange={handleHostValueChange}
                  className={styles.radio}
                >
                  {availableHosts.domain}
                </CustomRadio>
                <CustomRadio
                  id={'additional_host-radio'}
                  name={'host-value'}
                  value={availableHosts.server}
                  checked={selectedHostValue === availableHosts.server}
                  onChange={handleHostValueChange}
                  className={styles.radio}
                >
                  {availableHosts.server}
                </CustomRadio>
              </InputGroup>
            </div>
          </Accordion>
        </div>

        {renderGuideLink()}
      </div>
    </div>
  )
}

export default ProxyQuickResult
