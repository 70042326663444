import React from 'react'
import { useSelector } from 'react-redux'
import { saveAs } from 'file-saver'

import styles from '../../ProxyContent/ProxyContent.module.scss'

// import { getNormalizedPrice } from 'utils/functions'
import { outputMaskOptions } from 'utils/proxy/proxyConstants'
import { selectAvailableHosts } from 'store/user/selectors'

import { Check as CheckIcon, BookOpen as BookIcon } from '../../../../icons'
// import ProductPricingBlock from 'components/elements/blocks/ProductPricingBlock'
import InputGroup from 'components/elements/forms/InputGroup'
import CopyButton from 'components/elements/buttons/CopyButton'
import CustomButton from 'components/elements/buttons/CustomButton'
import CustomInput from 'components/elements/inputs/CustomInput'
/*import {
  selectProductPrice,
  selectProductPriceFetching,
} from 'store/pricing/selectors'*/
import CustomSelect from 'components/elements/inputs/CustomSelect'
import ResultBlock from 'components/elements/blocks/ResultBlock'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
import CustomRadio from 'components/elements/inputs/CustomRadio'
import Accordion from 'components/elements/blocks/Accordion'

interface IProxyListResultProps {
  selectedProxyType: any
  selectedOutputMask: ISelectOption
  resultArray: string[] | null
  selectedSpaceSymbol: string
  selectedAmpersandSymbol: string
  selectedHostValue: string
  connectionsAmount: number
  onOutputMaskChange: (option: ISelectOption) => void
  onSpaceSymbolChange: (value: string) => void
  onAmpersandSymbolChange: (value: string) => void
  onHostValueChange: (value: string) => void
  onConnectionsAmountChange: (val: any) => void
}

const ProxyListResult: React.FC<IProxyListResultProps> = (props) => {
  const {
    /*selectedProxyType,*/ resultArray,
    selectedOutputMask,
    selectedSpaceSymbol,
    selectedAmpersandSymbol,
    selectedHostValue,
    connectionsAmount,
    onOutputMaskChange,
    onSpaceSymbolChange,
    onAmpersandSymbolChange,
    onHostValueChange,
    onConnectionsAmountChange,
  } = props

  const availableHosts = useSelector(selectAvailableHosts)
  // const productPrice = useSelector(selectProductPrice)
  // const productPriceFetching = useSelector(selectProductPriceFetching)

  /*const [outputMask, setOutputMask] = useState(
    '{login}:{password}@{server}:{port}{login}:{password}@{server}:{port}'
  )

  const handleOutputMaskChange = (e: any) => {
    setOutputMask(e.target.value)
  }*/

  /*const getListResult = () => {
    let listResult: string[] = []

    configurationItems.forEach((item) => {
      for (let i = 1; i <= item.connectionsCount; i++) {
        const deepTargetResult = getDeepTargetResult(
          item.location.region,
          item.location.city,
          item.location.isp
        )

        listResult.push(
          getProxyResultString(
            proxyListResultSample,
            selectedProxyType.value,
            item.location.country,
            deepTargetResult,
            item.sessionType,
            item.sessionLength.value,
            `${item.sessionPrefix}_x${i}`
          )
        )
      }
    })

    return listResult
  }*/

  /*const getProductName = () => {
    if (selectedProxyType && selectedProxyType.shortLabel) {
      return `Proxy: ${selectedProxyType.shortLabel} pool`
    } else {
      return ''
    }
  }*/

  // const productName = getProductName()

  // const normalizedPrice = getNormalizedPrice(productPrice)

  // const productPriceString = productPrice ? `$${normalizedPrice} per 1 GB` : ''

  /*const handleTxtDownload = () => console.log('Txt')

  const handleCsvDownload = () => console.log('Csv')*/

  const handleOutputMaskChange = (option: any) => {
    onOutputMaskChange(option)
  }

  const handleSpaceSymbolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSpaceSymbolChange(e.target.value)
  }

  const handleAmpersandSymbolChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    onAmpersandSymbolChange(e.target.value)
  }

  const handleHostValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onHostValueChange(e.target.value)
  }

  const handleConnectionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value > 1000) {
      onConnectionsAmountChange(1000)
    } else {
      onConnectionsAmountChange(e.target.value)
    }
  }

  const handleConnectionsBlur = () => {
    if (connectionsAmount < 1) {
      onConnectionsAmountChange(1)
    }
  }

  const handleExportClick = () => {
    if (!!resultArray) {
      const blob = new Blob([resultArray.join('\n')], {
        type: 'text/plain;charset=utf-8',
      })
      saveAs(blob, 'proxy_list.txt')
    }
  }

  const resultList = resultArray?.map((result, index) => (
    <li className={styles.resultListItem} key={`result-list-item_${index}`}>
      <div className={styles.resultListItemNumber}>{index + 1}</div>
      <div>{result}</div>
    </li>
  ))

  /*const actionOptions = [
    {
      label: 'Txt',
      onClick: () => handleTxtDownload(),
    },
    {
      label: 'CSV',
      onClick: () => handleCsvDownload(),
    },
  ]*/

  return (
    <div className={styles.blockMain}>
      <div className={styles.blockBackgroundHeader}>
        <span className={styles.blockTitle}>Proxy list credentials</span>

        <div>
          <div className={styles.checkIconWrapper}>
            <CheckIcon />
          </div>
          Ready
        </div>
      </div>

      <div className={styles.resultContent}>
        {/*<ProductPricingBlock
          className={styles.pricingBlock}
          product={productName}
          price={productPriceString}
          loading={productPriceFetching}
        />*/}

        <div className={styles.resultMain}>
          <div className={styles.resultNotice}>
            Generate a bulk list of proxy endpoints, ideal for batch processing
            or managing multiple simultaneous connections.
          </div>

          <InputGroup
            labelTop={'Output mask'}
            className={styles.outputMaskBlock}
            boldLabel
          >
            <CustomSelect
              onChange={handleOutputMaskChange}
              value={selectedOutputMask}
              name={'url-result-output-mask'}
              isSearchable={false}
              options={outputMaskOptions}
            />
          </InputGroup>

          <ResultBlock
            slot={
              <div className={styles.listResultHeader}>
                <label className={styles.connectionsInputContainer}>
                  <span className={styles.connectionsInputLabel}>
                    Number of connections
                  </span>
                  <CustomInput
                    inputSize={'s'}
                    className={styles.connectionsInput}
                    value={connectionsAmount}
                    onChange={handleConnectionsChange}
                    onBlur={handleConnectionsBlur}
                  />
                </label>

                <div className={styles.listResultHeaderControls}>
                  <CustomButton
                    size={'s'}
                    onClick={handleExportClick}
                    className={styles.listResultHeaderBtn}
                    disabled={!resultArray}
                  >
                    Export .txt
                  </CustomButton>

                  <CopyButton
                    size={'s'}
                    text={!!resultArray ? resultArray.join('\n') : ''}
                    className={styles.listResultHeaderBtn}
                  />
                </div>
              </div>
            }
            className={styles.resultBlock}
          >
            <ol className={styles.resultList}>{resultList}</ol>
          </ResultBlock>

          {/*<InputGroup
            labelTop={'Proxy list'}
            className={styles.resultInputGroup}
          >
            <div className={styles.resultBlock}>
              <ol className={styles.resultList}>{resultList}</ol>
            </div>
          </InputGroup>

          <div className={styles.resultControls}>
            <CopyButton
              className={styles.resultControlBtn}
              text={!!resultArray ? resultArray.join('\n') : ''}
            >
              Copy text
            </CopyButton>

            <CopyButton
              className={styles.resultControlBtn}
              color={'outline'}
              text={'link'}
            >
              Copy link
            </CopyButton>

            <ComboButton
              className={styles.resultControlBtn}
              actionOptions={actionOptions}
            >
              Download
            </ComboButton>
          </div>*/}
        </div>

        <Accordion
          title={'Advanced output options'}
          className={styles.optionsAccordion}
        >
          <div className={styles.advancedOptions}>
            <InputGroup
              labelTop={'Replace space symbol in location options with:'}
              boldLabel
              className={styles.advancedOptionsInputGroup}
            >
              <CustomRadio
                id={'proxy-list-%20_space-radio'}
                name={'space-symbol'}
                value={'%20'}
                checked={selectedSpaceSymbol === '%20'}
                onChange={handleSpaceSymbolChange}
                className={styles.radio}
              >
                %20
              </CustomRadio>
              <CustomRadio
                id={'proxy-list-+_space-radio'}
                name={'space-symbol'}
                value={'+'}
                checked={selectedSpaceSymbol === '+'}
                onChange={handleSpaceSymbolChange}
                className={styles.radio}
              >
                +
              </CustomRadio>
              <CustomRadio
                id={'proxy-list-__space-radio'}
                name={'space-symbol'}
                value={'_'}
                checked={selectedSpaceSymbol === '_'}
                onChange={handleSpaceSymbolChange}
                className={styles.radio}
              >
                _
              </CustomRadio>
              <CustomRadio
                id={'proxy-list-default_space-radio'}
                name={'space-symbol'}
                value={' '}
                checked={selectedSpaceSymbol === ' '}
                onChange={handleSpaceSymbolChange}
                className={styles.radio}
              >
                don't replace
              </CustomRadio>
            </InputGroup>

            <InputGroup
              labelTop={'Replace “&” symbol in location options with:'}
              boldLabel
              className={styles.advancedOptionsInputGroup}
            >
              <CustomRadio
                id={'proxy-list_%26_ampersand-radio'}
                name={'proxy-list_ampersand-symbol'}
                value={'%26'}
                checked={selectedAmpersandSymbol === '%26'}
                onChange={handleAmpersandSymbolChange}
                className={styles.radio}
              >
                %26
              </CustomRadio>
              <CustomRadio
                id={'proxy-list_default_ampersand-radio'}
                name={'proxy-list_ampersand-symbol'}
                value={'&'}
                checked={selectedAmpersandSymbol === '&'}
                onChange={handleAmpersandSymbolChange}
                className={styles.radio}
              >
                don't replace
              </CustomRadio>
            </InputGroup>

            <InputGroup
              labelTop={'Use for host:'}
              boldLabel
              className={styles.advancedOptionsInputGroup}
            >
              <CustomRadio
                id={'proxy-list-default_host-radio'}
                name={'host-value'}
                value={availableHosts.domain}
                checked={selectedHostValue === availableHosts.domain}
                onChange={handleHostValueChange}
                className={styles.radio}
              >
                {availableHosts.domain}
              </CustomRadio>
              <CustomRadio
                id={'proxy-list-additional_host-radio'}
                name={'host-value'}
                value={availableHosts.server}
                checked={selectedHostValue === availableHosts.server}
                onChange={handleHostValueChange}
                className={styles.radio}
              >
                {availableHosts.server}
              </CustomRadio>
            </InputGroup>
          </div>
        </Accordion>

        <a
          className={styles.guideLink}
          href="https://helpcenter.soax.com/en/articles/9925411-how-to-use-a-proxy-list"
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <div className={styles.bookIconWrapper}>
            <BookIcon />
          </div>{' '}
          <span>How to set up and apply a proxy list</span>
        </a>
      </div>
    </div>
  )
}

export default ProxyListResult
