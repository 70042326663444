import React from 'react'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'

import styles from './AccountSettings.module.scss'

// import CustomButton from 'components/elements/buttons/CustomButton'
import CustomInput from 'components/elements/inputs/CustomInput'
import InputGroup from 'components/elements/forms/InputGroup'

interface IFormValues {
  first_name: string
  last_name: string
  email: string
}

interface IFormProps {
  info: {
    email: string
    first_name: string
    last_name: string
  }
  onFormSubmit: ({ first_name, last_name, email }: IFormValues) => void
}

const InnerForm = (props: FormikProps<IFormValues>) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    // isSubmitting,
  } = props

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit}>
        <InputGroup
          className={styles.inputGroup}
          labelTop={'Email'}
          error={!!(touched.email && errors.email) ? errors.email : ''}
        >
          <CustomInput
            //placeholder={'Email'}
            name={'email'}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly
          />
        </InputGroup>

        <InputGroup
          className={styles.inputGroup}
          labelTop={'First name'}
          error={
            !!(touched.first_name && errors.first_name) ? errors.first_name : ''
          }
        >
          <CustomInput
            //placeholder={'First name'}
            name={'first_name'}
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly
          />
        </InputGroup>

        <InputGroup
          className={styles.inputGroup}
          labelTop={'Last name'}
          error={
            !!(touched.last_name && errors.last_name) ? errors.last_name : ''
          }
        >
          <CustomInput
            // placeholder={'Last name'}
            name={'last_name'}
            value={values.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly
          />
        </InputGroup>

        {/* <CustomButton
          className={styles.btn}
          type={'submit'}
          disabled
          // loading={verifProcessing}
        >
          Update info
        </CustomButton> */}
      </form>
    </>
  )
}

const AccountSettingsForm = withFormik<IFormProps, IFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    first_name: props.info?.first_name || '',
    last_name: props.info?.last_name || '',
    email: props.info?.email || '',
  }),

  validationSchema: Yup.object().shape({
    first_name: Yup.string().required('Enter the first name'),
    last_name: Yup.string().required('Enter the last name'),
    email: Yup.string().required('Enter the email'),
  }),

  handleSubmit(values: IFormValues, { props }) {
    props.onFormSubmit(values)
  },
})(InnerForm)

export default AccountSettingsForm
