import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import NavItem, { INavItem } from '../NavItem/NavItem'
import { Logotype } from '../../icons'

import styles from './NavSidebar.module.scss'

interface INavSidebar {
  navSections?: INavSection[]
  isMobileMenuOpen: boolean
  isFullFunctional: boolean
  onToggleMobileMenu: () => void
}

export interface INavSection {
  label?: string
  items: INavItem[]
}

const NavSidebar: React.FC<INavSidebar> = (props) => {
  const {
    navSections,
    isMobileMenuOpen,
    isFullFunctional,
    onToggleMobileMenu,
  } = props

  const { pathname } = useLocation()

  const [isHovered, toggleHover] = useState<boolean>(false)

  useEffect(() => {
    if (isMobileMenuOpen) {
      onToggleMobileMenu()
    }
  }, [pathname])

  const handleMenuHide = () => {
    if (isMobileMenuOpen) {
      onToggleMobileMenu()
    }
  }

  const getNavList = (section: INavSection) => {
    return !!section && !!section.items.length
      ? section.items.map((item, index) => {
          return (
            <div key={`nav-item__${index}`} className={styles.navItem}>
              <NavItem
                item={item}
                label={item.label}
                isLabelShown={isHovered}
              />
              {!!item.items?.length ? (
                <div className={styles.navItemsWrapper}>
                  {item.items.map((item, index) => (
                    <NavItem
                      key={`inner-nav-item__${index}`}
                      item={item}
                      label={item.label}
                      isLabelShown={isHovered}
                      className={`
                    ${styles.navItem}
                    ${styles.inner}
                    ${isHovered ? styles.margined : ''}
                  `}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          )
        })
      : null
  }

  const sectionsList =
    !!navSections && !!navSections.length
      ? navSections.map((section, index) => (
          <div key={`nav-sidebar-section_${index}`} className={styles.section}>
            {getNavList(section)}
          </div>
        ))
      : null

  return (
    <>
      {isFullFunctional && (
        <div className={styles.desktopWrapper}>
          <aside
            className={styles.desktopSidebar}
            onMouseEnter={() => toggleHover(true)}
            onMouseLeave={() => toggleHover(false)}
          >
            <div className={styles.sidebarContent}>
              <div className={styles.desktopHeader}>
                <Link to={'/'} className={styles.desktopLogoWrapper}>
                  <Logotype className={styles.desktopLogo} />
                </Link>
              </div>

              <div className={styles.desktopContent}>{sectionsList}</div>
            </div>
          </aside>
        </div>
      )}

      {isFullFunctional && (
        <div className={styles.mobileWrapper}>
          <div className={styles.mobileHeader}>
            <button
              className={`
              ${styles.hamburger}
              ${isMobileMenuOpen ? styles.active : ''}
            `}
              onClick={onToggleMobileMenu}
            >
              <div className={styles.hamburgerBox}>
                <div className={styles.hamburgerInner} />
              </div>
            </button>

            <Link to={'/'} className={styles.mobileLogoWrapper}>
              <Logotype className={styles.mobileLogo} />
            </Link>
          </div>

          <div
            className={`
              ${styles.mobileMenu}
              ${isMobileMenuOpen ? styles.active : ''}
            `}
          >
            <div className={styles.mobileMenuWrapper}>
              <div className={styles.mobileMenuContent}>{sectionsList}</div>
            </div>
          </div>

          <div
            className={`
            ${styles.mobileMenuOverlay}
            ${isMobileMenuOpen ? styles.active : ''}
          `}
            onClick={handleMenuHide}
          />
        </div>
      )}
    </>
  )
}

export default NavSidebar
