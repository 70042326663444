import React from 'react'

import { outputMaskOptions } from 'utils/proxy/proxyConstants'

import styles from '../../ProxyContent/ProxyContent.module.scss'

import InputGroup from 'components/elements/forms/InputGroup'
import CopyButton from 'components/elements/buttons/CopyButton'
import ResultBlock from 'components/elements/blocks/ResultBlock'
import CustomSelect from 'components/elements/inputs/CustomSelect'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'

interface IUrlResultProps {
  result: string
  selectedOutputMask: ISelectOption
  onOutputMaskChange: (option: ISelectOption) => void
}

const UrlResult: React.FC<IUrlResultProps> = (props) => {
  const { result, selectedOutputMask, onOutputMaskChange } = props

  const handleOutputMaskChange = (option: any) => {
    onOutputMaskChange(option)
  }

  return (
    <div>
      <div className={styles.resultNotice}>
        Choose if your software accepts proxy details as a URL.
      </div>

      <InputGroup
        labelTop={'Output mask'}
        className={styles.outputMaskBlock}
        boldLabel
      >
        <CustomSelect
          onChange={handleOutputMaskChange}
          value={selectedOutputMask}
          name={'url-result-output-mask'}
          isSearchable={false}
          options={outputMaskOptions}
        />
      </InputGroup>

      <ResultBlock
        slot={<CopyButton size={'s'} text={result} />}
        className={styles.resultBlock}
      >
        {result}
      </ResultBlock>
    </div>
  )
}

export default UrlResult
