import { IUserAction, IUserState } from 'models/user'
import { UserActionsTypes } from './actions'

const initialState: IUserState = {
  initialLoading: true,
  isUserInitFailed: false,
  fetching: false,
  subscriptionInfo: null,
  subscriptionInfoFetching: false,
  balanceInfo: null,
  balanceInfoFetching: false,
  overviewFetching: false,
  userOverviewUrl: null,
  firstName: null,
  lastName: null,
  email: null,
  tempFirstName: null,
  tempLastName: null,
  scrapperApiKey: null,
  scrapperApiKeyFetching: false,
  proxyPackages: null,
  proxyPackagesFetching: true,
  intercomHash: null,
  userHash: null,
  availableHosts: null,
}

const userReducer = (state: IUserState = initialState, action: IUserAction) => {
  switch (action.type) {
    case UserActionsTypes.USER_INITIALIZE:
      return {
        ...state,
        initialLoading: true,
        isUserInitFailed: false,
      }
    case UserActionsTypes.USER_INITIALIZE_SUCCESS:
      return {
        ...state,
        initialLoading: false,
        isUserInitFailed: false,
        intercomHash: action.intercomHash,
        userHash: action.userHash,
      }
    case UserActionsTypes.USER_INITIALIZE_FAIL:
      return {
        ...state,
        initialLoading: false,
        isUserInitFailed: true,
      }

    case UserActionsTypes.USER_GET_BALANCE:
      return {
        ...state,
        balanceInfoFetching: true,
      }
    case UserActionsTypes.USER_GET_BALANCE_SUCCESS:
      return {
        ...state,
        balanceInfo: action.balanceInfo,
        userOverviewUrl: action.userOverviewUrl,
        balanceInfoFetching: false,
      }
    case UserActionsTypes.USER_GET_BALANCE_FAIL:
      return {
        ...state,
        balanceInfoFetching: false,
      }

    case UserActionsTypes.USER_GET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionInfoFetching: true,
      }
    case UserActionsTypes.USER_GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionInfo: action.subscriptionInfo,
        subscriptionInfoFetching: false,
      }
    case UserActionsTypes.USER_GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        subscriptionInfoFetching: false,
      }

    case UserActionsTypes.USER_GET_OVERVIEW:
      return {
        ...state,
        overviewFetching: true,
      }
    case UserActionsTypes.USER_GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        userOverviewUrl: action.userOverviewUrl,
        overviewFetching: false,
      }
    case UserActionsTypes.USER_GET_OVERVIEW_FAIL:
      return {
        ...state,
        userOverviewUrl: null,
        overviewFetching: false,
      }

    case UserActionsTypes.USER_SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.firstName,
      }
    case UserActionsTypes.USER_SET_LAST_NAME:
      return {
        ...state,
        lastName: action.lastName,
      }
    case UserActionsTypes.USER_SET_EMAIL:
      return {
        ...state,
        email: action.email,
      }

    case UserActionsTypes.USER_SET_TEMP_FIRST_NAME:
      return {
        ...state,
        tempFirstName: action.tempFirstName,
      }
    case UserActionsTypes.USER_SET_TEMP_LAST_NAME:
      return {
        ...state,
        tempLastName: action.tempLastName,
      }

    case UserActionsTypes.USER_GET_SCRAPPER_API_KEY:
      return {
        ...state,
        scrapperApiKeyFetching: true,
      }
    case UserActionsTypes.USER_GET_SCRAPPER_API_KEY_SUCCESS:
      return {
        ...state,
        scrapperApiKeyFetching: false,
        scrapperApiKey: action.scrapperApiKey,
      }
    case UserActionsTypes.USER_GET_SCRAPPER_API_KEY_FAIL:
      return {
        ...state,
        scrapperApiKeyFetching: false,
      }

    case UserActionsTypes.USER_GET_PROXY_PACKAGES:
      return {
        ...state,
        proxyPackagesFetching: true,
      }
    case UserActionsTypes.USER_GET_PROXY_PACKAGES_SUCCESS:
      return {
        ...state,
        proxyPackagesFetching: false,
        proxyPackages: action.proxyPackages,
        availableHosts: action.availableHosts,
      }
    case UserActionsTypes.USER_GET_PROXY_PACKAGES_FAIL:
      return {
        ...state,
        proxyPackagesFetching: false,
      }
    default:
      return state
  }
}

export default userReducer
