import React, { ReactElement } from 'react'

import styles from './ResultBlock.module.scss'

interface IResultBlockProps {
  // text: string
  className?: string
  slot?: string | ReactElement
  children?: string | ReactElement
}

const ResultBlock: React.FC<IResultBlockProps> = (props) => {
  const { slot, children, className } = props

  return (
    <div
      className={`
        ${className || ''}
      `}
    >
      <div className={styles.header}>{slot}</div>

      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default ResultBlock
