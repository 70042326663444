import React from 'react'

import styles from '../../ProxyContent/ProxyContent.module.scss'

import CustomSelect from 'components/elements/inputs/CustomSelect'
import InputGroup from 'components/elements/forms/InputGroup'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
// import CustomButton from 'components/elements/buttons/CustomButton'
import ProxyConfigurationForm from '../../ProxyConfigurationForm'
import { ProxyPoolDescription } from 'utils/proxy/proxyConstants'

interface IProxyListConfigurationProps {
  selectedProxyType: any
  selectedTimeInterval: any
  customTimeInterval: string
  selectedCountry: any
  selectedRegion: any
  selectedCity: any
  selectedIsp: any
  isBindIPActive: boolean
  activeSessionLengthType: any
  sessionPrefix: string
  // connectionsAmount: number
  proxyTypesOptions: ISelectOption[]
  availableSessionIntervals: ISelectOption[]
  onProxyTypeChange: (option: ISelectOption) => void
  onCountryChange: (option: ISelectOption) => void
  onRegionChange: (option: ISelectOption) => void
  onCityChange: (option: ISelectOption) => void
  onIspChange: (option: ISelectOption) => void
  onTimeIntervalChange: (option: ISelectOption) => void
  onDeepTargetsReset: () => void
  onCustomTimeIntervalChange: (val: string) => void
  onBindIp: () => void
  onActiveSessionLengthChange: (e: any) => void
  onSessionPrefixChange: (e: any) => void
  // onConnectionsAmountChange: (e: any) => void
  // onGenerate: () => void
}

const ProxyListConfiguration: React.FC<IProxyListConfigurationProps> = (
  props
) => {
  const {
    selectedProxyType,
    selectedCountry,
    selectedRegion,
    selectedCity,
    selectedIsp,
    selectedTimeInterval,
    customTimeInterval,
    isBindIPActive,
    activeSessionLengthType,
    sessionPrefix,
    proxyTypesOptions,
    availableSessionIntervals,
    // connectionsAmount,
    onProxyTypeChange,
    onCountryChange,
    onRegionChange,
    onCityChange,
    onIspChange,
    onDeepTargetsReset,
    onTimeIntervalChange,
    onCustomTimeIntervalChange,
    onBindIp,
    onActiveSessionLengthChange,
    onSessionPrefixChange,
    // onConnectionsAmountChange,
    // onGenerate,
  } = props

  const handleProxyTypeChange = (option: any) => {
    onProxyTypeChange(option)
  }

  return (
    <>
      <div className={styles.blockHeader}>
        <span className={styles.blockTitle}>Connection setup</span>
      </div>

      <InputGroup
        className={styles.proxyTypeGroup}
        labelTop={'Proxy type'}
        boldLabel
        name={'pool_select_group'}
        description={ProxyPoolDescription}
      >
        <CustomSelect
          onChange={handleProxyTypeChange}
          value={selectedProxyType}
          name={'proxy-type-select'}
          isSearchable={false}
          options={proxyTypesOptions}
        />
      </InputGroup>

      <div className={styles.formWrapper}>
        <ProxyConfigurationForm
          selectedProxyType={selectedProxyType}
          // isConnectionsShown={true}
          selectedCountry={selectedCountry}
          selectedRegion={selectedRegion}
          selectedCity={selectedCity}
          selectedIsp={selectedIsp}
          activeSessionLengthType={activeSessionLengthType}
          selectedTimeInterval={selectedTimeInterval}
          customTimeInterval={customTimeInterval}
          isBindIPActive={isBindIPActive}
          sessionPrefix={sessionPrefix}
          // connectionsAmount={connectionsAmount}
          availableTimeIntervals={availableSessionIntervals}
          onCountryChange={onCountryChange}
          onRegionChange={onRegionChange}
          onCityChange={onCityChange}
          onIspChange={onIspChange}
          onDeepTargetsReset={onDeepTargetsReset}
          onActiveSessionLengthChange={onActiveSessionLengthChange}
          onTimeIntervalChange={onTimeIntervalChange}
          onCustomTimeIntervalChange={onCustomTimeIntervalChange}
          onBindIp={onBindIp}
          onSessionPrefixChange={onSessionPrefixChange}
          // onConnectionsAmountChange={onConnectionsAmountChange}
        />
      </div>

      {/*<CustomButton onClick={onGenerate} wide>
        Generate
      </CustomButton>*/}
    </>
  )
}

export default ProxyListConfiguration
