import React, { useState } from 'react'

import styles from './Accordion.module.scss'

import { CaretDown as CaretIcon } from '../../../icons'

interface IAccordionProps {
  title: string
  children: React.ReactNode
  className?: string
}

const Accordion: React.FC<IAccordionProps> = (props) => {
  const { title, children, className } = props

  const [isAccordionActive, toggleAccordion] = useState(false)

  const handleToggle = () => toggleAccordion(!isAccordionActive)

  return (
    <div
      className={`
        ${styles.accordion}
        ${className || ''}
      `}
    >
      <button
        className={`
            ${styles.accordionToggle}
            ${isAccordionActive ? styles.active : ''}
          `}
        onClick={handleToggle}
      >
        <>
          {title}{' '}
          <div className={styles.toggleIconWrapper}>
            <CaretIcon />
          </div>
        </>
      </button>

      <div
        className={`
            ${styles.accordionContent}
            ${isAccordionActive ? styles.active : ''}
          `}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
