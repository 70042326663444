import React from 'react'

import styles from '../../ProxyContent/ProxyContent.module.scss'

import InputGroup from 'components/elements/forms/InputGroup'
import CustomInput from 'components/elements/inputs/CustomInput'

interface ICredentialsResultProps {
  login: string
  password: string
  server: string
  port: string
}

const CredentialsResult: React.FC<ICredentialsResultProps> = (props) => {
  const { login, password, server, port } = props

  return (
    <div>
      <div className={styles.resultNotice}>
        <p>
          Use for a simple connection by entering credentials directly in your
          software.
        </p>
        <p>
          To connect, please enter the following credentials in your preferred
          software.
        </p>
      </div>

      <InputGroup
        labelLeft={'Username'}
        className={styles.resultInputGroup}
        labelClassName={styles.resultsLeftLabel}
      >
        <CustomInput copyable value={login} />
      </InputGroup>

      <InputGroup
        labelLeft={'Password'}
        className={styles.resultInputGroup}
        labelClassName={styles.resultsLeftLabel}
      >
        <CustomInput copyable value={password} />
      </InputGroup>

      <InputGroup
        labelLeft={'Host'}
        className={styles.resultInputGroup}
        labelClassName={styles.resultsLeftLabel}
      >
        <CustomInput copyable value={server} />
      </InputGroup>

      <InputGroup
        labelLeft={'Port'}
        className={styles.resultInputGroup}
        labelClassName={styles.resultsLeftLabel}
        childrenDirection={'vertical'}
      >
        <CustomInput copyable value={port} />
      </InputGroup>
    </div>
  )
}

export default CredentialsResult
