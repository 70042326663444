import {
  dcSessionTimeIntervals,
  ispSessionTimeIntervals,
  mobileSessionTimeIntervals,
  residentialSessionTimeIntervals,
} from './proxyConstants'

export const getProxyResultString = (
  sample: string,
  packageId: string,
  packageKey: string,
  countryValue: string,
  deepTargetString: string,
  sessionLengthType: string,
  timeIntervalValue: string,
  isBinded: boolean,
  sessionPrefix: string,
  hostValue: string
) => {
  let result = sample.replace('{{package_id}}', packageId)

  result = result.replace('{{package_key}}', packageKey)
  result = result.replace('{{host}}', hostValue)

  if (countryValue !== 'any') {
    const locationString = `-country-${countryValue}` + deepTargetString

    result = result.replace('{{location}}', locationString)
  } else {
    result = result.replace('{{location}}', '')
  }

  if (sessionLengthType === 'same' && isBinded) {
    result = result.replace(
      '{{session_length}}',
      `-sessionlength-${timeIntervalValue}-bindttl-${timeIntervalValue}`
    )
  } else if (sessionLengthType === 'same') {
    result = result.replace(
      '{{session_length}}',
      `-sessionlength-${timeIntervalValue}`
    )
  } else {
    result = result.replace('{{session_length}}', '')
    result = result.replace('{{session_prefix}}', '')
  }

  result = result.replace('{{session_prefix}}', `-sessionid-${sessionPrefix}`)

  return result
}

export const getAvailableSessionIntervals = (proxyType: string) => {
  if (proxyType === 'wifi') {
    return residentialSessionTimeIntervals
  } else if (proxyType === 'mobile') {
    return mobileSessionTimeIntervals
  } else if (proxyType === 'dc') {
    return dcSessionTimeIntervals
  } else if (proxyType === 'isp') {
    return ispSessionTimeIntervals
  } else {
    return [{ label: '', value: '' }]
  }
}
