import React from 'react'

import styles from '../../ProxyContent/ProxyContent.module.scss'

import InputGroup from 'components/elements/forms/InputGroup'
import CopyButton from 'components/elements/buttons/CopyButton'
import ResultBlock from 'components/elements/blocks/ResultBlock'

interface ICurlResultProps {
  result: string
}

const CurlResult: React.FC<ICurlResultProps> = (props) => {
  const { result } = props

  return (
    <div>
      <div className={styles.resultNotice}>
        Select for command line or API connections, running proxy requests
        directly with cURL. Copy this request and paste it into Command Line
        (Windows) or Terminal (macOS/Linux):
      </div>

      <ResultBlock
        slot={<CopyButton size={'s'} text={result} />}
        className={styles.resultBlock}
      >
        {result}
      </ResultBlock>

      <InputGroup
        labelTop={'A successfully completed request looks like this:'}
      >
        <div className={styles.resultExample}>
          {
            '{"status":true,"reason":"","data":{"carrier":"","city":"Melbourne","country_code":"US","country_name":"United States","ip":"97.102.236.127","isp":"Spectrum","region":"Florida"}}  '
          }
        </div>
      </InputGroup>
    </div>
  )
}

export default CurlResult
