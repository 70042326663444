import { get } from 'utils/request'

export const initUser = async (
  referral_param_click_id?: string,
  new_dash?: boolean
) =>
  await get(`/api/init-user`, { params: { referral_param_click_id, new_dash } })

export const getUserSubscription = async () => await get(`/api/subscriptions`)

export const getUserBalance = async () => await get(`/api/balance`)

export const getUserOverview = async () => await get('/api/portal-url')

export const getScrapperApiKey = async () => await get('/api/keys/scrapper')

export const getProxyPackages = async () => await get('/api/packages')

export const getAvailableHosts = async () => await get('/api/random-server')
