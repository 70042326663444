import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import styles from './ProxyContent.module.scss'

import TabsList from 'components/elements/tabs/TabsList'
import TabPanels from 'components/elements/tabs/TabPanels'
import { ITabItem } from 'components/elements/tabs/TabsList/TabsList'
import ProxyQuickConnect from '../ProxyQuickConnect'
import ProxyListGenerator from '../ProxyListGenerator'
// import ProxyWhitelist from '../ProxyWhitelist'
import Loader from 'components/elements/progress/Loader'

import { ServiceActionsTypes } from 'store/service/actions'
import {
  selectProxyPackages,
  selectProxyPackagesFetching,
} from 'store/user/selectors'

const ProxyContent: React.FC = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const proxyPackagesFetching = useSelector(selectProxyPackagesFetching)
  const proxyPackages = useSelector(selectProxyPackages)

  const activeTabParam = searchParams.get('active_tab')

  const tabItems = [
    {
      label: 'Quick connect',
      value: 'quick_connect',
    },
    {
      label: 'Proxy list generator',
      value: 'proxy_list',
    },
    /*{
      label: 'IP whitelist',
      value: 'whitelist',
    },*/
  ]

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const handleTabChange = (index: number, item?: ITabItem) => {
    setActiveTabIndex(index)

    if (item?.value) setSearchParams({ active_tab: item.value })
  }

  const tabTypes = tabItems.map((item) => item.value)

  useEffect(() => {
    dispatch({
      type: ServiceActionsTypes.SERVICE_GET_LOCATIONS,
    })
  }, [])

  useEffect(() => {
    const activeSettingsParamIndex = activeTabParam
      ? tabTypes.indexOf(activeTabParam)
      : null

    if (
      activeSettingsParamIndex &&
      activeSettingsParamIndex !== activeTabIndex
    ) {
      setActiveTabIndex(activeSettingsParamIndex)
    }
  }, [tabTypes, activeTabParam, activeTabIndex])

  if (proxyPackagesFetching) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    )
  } else if (!proxyPackages || !proxyPackages.length) {
    return <div>No packages created</div>
  }

  const tabContentArray = [
    <ProxyQuickConnect activeProxySectionIndex={activeTabIndex} />,
    <ProxyListGenerator activeProxySectionIndex={activeTabIndex} />,
    /*<ProxyWhitelist />,*/
  ]

  return (
    <section className={styles.section}>
      <TabsList
        items={tabItems}
        type={'header'}
        activeTabIndex={activeTabIndex}
        onChange={handleTabChange}
      />
      <div className={styles.content}>
        <TabPanels items={tabContentArray} activeTabIndex={activeTabIndex} />
      </div>
    </section>
  )
}

export default ProxyContent
